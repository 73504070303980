import { webData } from '../constants/data';
import './sectors.scss';

const Sectors = ({sectionRefs}) => {
  return (
   <>
   <section className='sectors section' id='sector' ref={sectionRefs.current.sector}>
				<div className='container'>
					<div className="row">
						<div className="col-lg-12">
							<div className="section-title">
								<h2>{webData.sectorHeading}</h2>
								<img src={`images/${webData.commonImg}.png`} alt="section image" />
								<p>{webData.sectorContent}</p>
							</div>
						</div>
					</div>
					<div className="row">
						{
							webData.sectorDetails.map((item, index) => {
								return <div className="col-lg-4 col-md-4 col-12" key={index}>
									<div className='zoom'>
										<img src={`images/${item.image}.jpg`} alt='site image'></img>
										<div className='zoom-content'>
											<h2>{item.sectorName}</h2>
											<p>{item.para}</p>
										</div>
									</div>
									<h3>{item.sectorHeading}</h3>
								</div>
							})
						}
					</div>
				</div>
			</section>
   </>
  )
}
export default Sectors;
