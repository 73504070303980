import { useEffect, useState } from "react";
import { webData } from "../../constants/data";
import './navBar.scss';
import { Link } from "react-router-dom";
import logoIcon from '../../../assets/images/logo.png';


const NavBar = () => {
    const [scroll, setScroll] = useState();
    const [menuToggle, setMenuToggle] = useState(false);

    const handleMenu = () => {
        setMenuToggle(!menuToggle)
    }

  


    const handleScroll = (id) => {
        const element = document.getElementById(id);
        setMenuToggle(false)
    element.scrollIntoView();
       
    }

    return (
        <>
            <header className={`header ${scroll ? "sticky" : "header"}`}>
                <div className="header-inner">
                    <div className="container">
                        <div className='header-nav'>
                            <div className='logo'>
                                <a href="#" >
                                    <img src={logoIcon}  alt="logo"/>
                                </a>
                            </div>
                            {
                                menuToggle ? <i className="fa fa-times menu" aria-hidden="true" onClick={handleMenu}></i> :
                                    <i className="fa fa-bars menu" aria-hidden="true" onClick={handleMenu}></i>
                            }
                            <div className={`nav_cover ${menuToggle ? 'show' : 'hide'}`}>
                                <ul className="nav">
                                    {
                                        webData.links.map((item,index)=>{
                                            return (
                                                <li key={index} onClick={()=>handleScroll(item.id)}>
                                                    <Link to="/">{item.text}</Link>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                                <div className="login">
                                    <Link to="https://waveplus.dwellsmart.co.in" className="btn">{webData.login}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}


export default NavBar;