export const webData = {
    "commonImg": "commonimg",
//===================== Navbar start here ===============//
    // "logo": 'https://dwellsmart.co.in/resources/56ed99338809400a171c667b_logo.png',
    "login": "Login",
    "links": [
        
        {
            "text": "How wave+ works",
            "id": "wavework"
        },
        {
            "text": "Products",
            "id": "product",
        },
        {
            "text": "Sectors",
            "id": "sector"
        },
        {
            "text": "About Us",
            "id": "about"
        },
        {
            "text": "Contact Us",
            "id": "contact",
        },
    ],

//=================== slider section start here ============//
    sliderList: [
        {
            image: 'slider1',
            mobileImage: 'mobileslider1',
        },
        {
            image: 'slider2',
            mobileImage: 'mobileslider2',
        },
        {
            image: 'slider3',
            mobileImage: 'mobileslider3',
        },
    ],

//=================== about section start here ============//
    "aboutHeading": 'ABOUT US',
    "aboutContent": 'DwellSmart revolutionizing the way you manage your electricity consumption. Our advanced prepaid metering system empowers you with real-time insights into your energy usage.',
    "aboutMe": 'Who We Are',
    "aboutMedetail1": "Established in 2015, DwellSMART Pvt Ltd leads the way in smart energy metering solutions for residential high-rise apartments and commercial complexes. Our innovative prepaid and smart metering systems empower users to monitor, regulate, and optimize energy consumption in real-time, promoting sustainability and cost-effectiveness.",
    "aboutMedetail2":" At DwellSmart Pvt Ltd, we specialize in crafting customized solutions for a diverse range of clients, including housing societies, shopping complexes, and commercial buildings. Our approach involves a thorough understanding of each client's unique needs, allowing us to deliver tailored solutions that effectively optimize energy management with in their premises.",

//================= wave works section start here ===========//
    "wavePlusWoksHead": "Wave+ Estate Management Platform",
    "wavePlusWoksData": "Wave+ is an IoT (internet of Things) based ESTATE Management Suite which is fully integrated with SMART Energy Metering & Billing System.It is the prepaid metering platform that is compatible with energy meters from various reputed brands like  Havells, Enertrak, Sunstar, Larsen & Toubro, Techno and many more.",
    feactureKeys1: [
        {
            img: 'icon1',
            keyHeading: 'Energy Management',
            para: 'Real-time monitoring of energy consumption for users to track their usage patterns. Analysis of historical data to identify trends and optimize energy usage.'
        },
        {
            img: 'icon2',
            keyHeading: 'System Health Monitor ',
            para: 'Continuous monitoring ensures smart prepaid meter device health and performance. It detects anomalies, malfunctions in hardware or software, and alerts for timely intervention.'
        },
        {
            img: 'icon3',
            keyHeading: 'Automatic meter reading',
            para: 'Automated collection of meter data without the need for manual intervention.Utilization of IoT (Internet of Things) technology to gather data from meters and transmit.'
        },

    ],
    feactureKeys2: [
        {
            img: 'icon4',
            keyHeading: ' Automated Reports',
            para: 'Generate of customized reports energy consumption data, trends,& insights.Visualization of data using graphs and other interactive tools to make it easier.'
        },
        {
            img: 'icon5',
            keyHeading: 'Online Recharge',
            para: 'Secure online platform for users to recharge their prepaid meter accounts support for multiple payment methods including credit/debit cards, net banking, mobile wallets etc.'
        },
        {
            img: 'icon5',
            keyHeading: 'Live Data Visualization',
            para: 'Live data visualization is crucial for monitoring electricity efficiently. Wave+ offers interactive dashboards displaying real-time consumption data.'
        },
    ],

//================= tabbing section start here =========//
    tabData1: {
        "tabHead1": "Key Features",
        "tabHead2": "Components of wave+ ",
        "key1": 'architecture',
        "key2": 'works'
    },
    
    aboutKeys: [
        {
            "keyName": "A. Energy Meters:-",
            "keyData": "Energy Meters are microprocessor-based energy measurement device. These meters are capable of recording energy consumption for multiple input energy sources viz, GRID, DG, Solar etc. These meters collect metering data locally and transmit to a data collector, whenever requested by server."
        },
        {
            "keyName": "B. Data Concentrator (DCU):-",
            "keyData": "Data Concentrator is a device which is connected to the on-premises internet router. It acquires metering data from the energy meters, whenever requested by the server application and sends the metering data back to the server.",
        },
        {
            "keyName": "C. Wave+ Cloud Server Application:-",
            "keyData": "Wave+ Cloud Server Application is a software application deployed over a highly secured and reliable cloud infrastructure. This application is the main engine, it hosts the user accounts associated to each energy meter and has a capability to communicate with these energy meters. It fetches the energy consumption data along with other electrical parameters on a periodic basis and computes the daily consumption charges as per the charging tariff defined with in the application. Once the user ‘s account balance goes below a configurable  value the application instructs the corresponding energy meter to turn off the energy supply. This application allows the users to recharge their accounts online with the help of Credit Card. Debit Card or Net Banking etc. for restoring the energy supply from the energy meter."
        },
    ],
  
//=============== contacts page start here =============//
    "contactHeading": "Contact Us",
    "contactData": 'Contact With Us',
    "content": 'If you have any questions or need any assistance regarding us, please get in touch.',
    "support":"support",
    "email":"support@dwellsmart.co.in",
    "timing":"opening Days: ",
    "openTime":"Monday to Saturday : 9AM to 6PM",
    "closeDay":"Closing Days:",
    "close": "Sunday & Official Holidays",

//================= sector section start here ===========//
    "sectorHeading": 'Sectors We Deal in',
    "sectorContent": '',
    sectorDetails: [
        {
            "image": 'sector1',
            "sectorName": 'Residential Apartments & Villas',
            "sectorHeading": 'Residential Apartments & Villas',
            "para": 'We understand the importance of efficient and reliable utility services for residential communities. With our prepaid meter solutions, we aim to provide hassle-free and convenient access to electricity.'
        },
        {
            "image": 'sector2',
            "sectorName": 'Co-Living and PGs',
            "sectorHeading": 'Co-Living and PGs',
            "para": ' We provided transparent and accurate billing, ensuring that Co-Living only pay for the electricity they use. Detailed usage reports are available to both Co-Living and PGs managers, promoting transparency and accountability.'
        },
        {
            "image": 'sector3',
            "sectorName": 'Commercial Complexes',
            "sectorHeading": 'Commercial Complexes',
            "para": "Revolutionize energy management in your bustling commercial complex and  shopping mall with our advanced prepaid meter. monitor and control electricity consumption, tailored for your commercial property's dynamic needs."
        },
    ],

//===================== footer start here ==============//
    "pageTitle": "social Links",
    "description": "Dwellsmart offers cutting-edge electric prepaid meters, revolutionizing how consumers     manage their electricity usage.",
    "quickLinks": 'Quick Links',
    "address": 'address',
    "headoffice": "Head Office",
    "headofficeAddress": "E-31, SITE 4, Block F, Industrial Area, Surajpur Site 4, Greater Noida, Uttar Pradesh 201310",
    "corporate": 'Branch Office',
    "corporateAddress": "B-302 , LOGIX TECHNOVA, Block B, Sector- 132, Noida, Uttar Pradesh 201304",
    "copy": '© Copyright 2024 | All Rights Reserved by ',
    socialIcons: [
        { icon: "linkedin", url: "https://www.linkedin.com/company/dwellsmart"},
        { icon: 'google-plus', url: "https://www.linkedin.com/company/dwellsmart"},
        { icon: 'facebook', url: "https://www.linkedin.com/company/dwellsmart" },
        { icon: 'telegram', url: "https://www.linkedin.com/company/dwellsmart" },
    ],
    "links1": [
        {
            "text": " wave+ works",
            "id": "wavework"
        },
        {
            "text": "Contact us",
            "id": "contact"
        },
        {
            "text": "About Us",
            "id": "about"
        },
        {
            "text": "Products",
            "id": "product",
        },
    ],


//=============== products section start here ===============//

// mobile app section data-
    "appHeading": ' Wave+ Mobile  App ',
    "appContent": " Gain instant access to real-time data visualization tools that bring your data to life. Whether you're monitoring energy usage, tracking financial metrics, or analyzing customer behavior, Wave+ provides the insights you need when you need them.",
    "appDetails": [
        {
            "image": 'mobile1',
            "deviceFeature": 'Get Started with Wave+ App',
            "deviceSpecification": 'Join the wave of energy-conscious consumers who are taking control of their electricity usage with Wave+. Download the app now and embark on a journey towards smarter, more efficient energy management.',
            "realTime": 'Real-Time Data Visualization',
            "realTimeSpecification": "Wave+ provides you with live updates on voltage and current consumption, displayed in an intuitive and easy-to-understand diagram. With just a glance, you can track how much energy you're utilizing at any given moment, helping you make informed decisions to optimize your usage and save on costs",
            "scanner": 'scanner',
        },
    ],
    
// meters section data-
    "meterHeading": 'OUR PRODUCTS',
    "meterContent": 'As the energy landscape evolves, so too must the tools and technologies we rely on to manage it    effectively. At DwellSmart Pvt Ltd, we understand the challenges facing utility companies in balancing energy production, distribution and delivery while also striving to reduce costs and optimize resource allocation.',
    meterDetails1: [
        {
            "name": 'THREE PHASE PREPAID METER',
            "data": 'Three Phase Meter guarantees exceedingly accurate assessment of energy consumption spanning the triadic phases, furnishing indisputable data for utility stakeholders and consumers alike.',
            "image": 'three_phase',
        },
        {
            "name": 'SINGLE PHASE PREPAID METER',
            "data": 'Single Phase Dual Source Meter ensures precise measurement of energy consumption, providing reliable data for both utility companies and consumers..',
            "image": 'single_phase',
        },
        {
            "name": 'HT  PREPAID METER',
            "data": 'Single Phase Dual Source Meter ensures precise measurement of energy consumption, providing reliable data for both utility companies and consumers..',
            "image": 'ht_meter',
        },
    ],
}




