import { BrowserRouter as Router, Route, Routes, } from "react-router-dom";
import NavBar from "./components/common/navbar/Navbar";
import HeroSlider from './components/common/slider/Slider';
import About from "./components/about/About";
import Waveworks from "./components/waveworks/Waveworks";
import Product from "./components/product/Product";
import Sectors from "./components/sectors/Sectors";
import Contacts from "./components/contacts/Contacts";
// import Login from "./components/login/Login";
import Footer from "./components/common/footer/Footer";
import React, { useRef } from "react";
import { webData } from "./components/constants/data";


const App = () => {

  const sectionRefs = useRef(webData.links.reduce((acc, item) => {
    acc[item.id] = React.createRef();
    return acc;
}, {}));


  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={
            <>
              <NavBar />
              <HeroSlider />
              <Waveworks sectionRefs={sectionRefs} />
              <Product sectionRefs={sectionRefs}/>
              <About sectionRefs={sectionRefs}/>
              <Sectors sectionRefs={sectionRefs}/>
              <Contacts sectionRefs={sectionRefs}/>
              <Footer />
            </>
          }
          />
          {/* <Route path='/login' element={<Login />} /> */}
        </Routes>
      </Router>
    </>
  );
}

export default App;